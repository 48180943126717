<template>
  <gc-show-phone-number-slide
    class="global-components"
    :name-tag-title="'Your Number'"
    :phone-number="phoneNumber"
    :slide-description="slideDescription"
    @update:copy="handleCopy($event)"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { copyClipboardMixin } from '@/mixins/copyClipboardMixin'

export default {
  name: 'ShowPhoneNumberSlide',

  mixins: [
    copyClipboardMixin,
    makeToastMixin,
  ],

  props: {
    slide: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('stagelineV3', [
      'accountsStage',
    ]),
    slideDescription() {
      return JSON.parse(this.slide)?.description || ''
    },
    phoneNumber() {
      return this.accountsStage?.data?.virtual_phone?.number || ''
    },
  },

  methods: {
    handleCopy(event) {
      const textToCopy = event?.detail?.[0]
      this.copyToClipboard(textToCopy, null, this.successToast, this.errorToast)
    },
  },
}
</script>
