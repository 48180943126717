var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("gc-show-phone-number-slide", {
    staticClass: "global-components",
    attrs: {
      "name-tag-title": "Your Number",
      "phone-number": _vm.phoneNumber,
      "slide-description": _vm.slideDescription,
    },
    on: {
      "update:copy": function ($event) {
        return _vm.handleCopy($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }